<template>
  <div class="battles-players">
    <div class="battles-players__icons" :class="[props.isClickable && 'battles-players__icons--clickable']">
      <template v-for="(member, slot) in props.slots" :key="slot">
        <div
          v-if="!(!member && (battleStatus === EBattleStatuses.STARTED || battleStatus === EBattleStatuses.FINISHED))"
          :style="properStyles[+slot]"
          :class="[
            'battles-players__icons_icon',
            !member && 'battles-players__icons_icon--empty',
            member?.isWinner && battleStatus === EBattleStatuses.FINISHED && 'battles-players__icons_icon--winner',
            defineLevelClasses(+slot, 'battles-players__icons_icon'),
          ]"
          @click="props.isClickable && !member && emits('onSlotClick', slot)"
        >
          <SvgoBattlePlusIcon v-if="device.isDesktop && !member" class="icon__plus" />
          <img v-if="member" class="icon__image" :src="member.avatar ?? ''" alt="" />
        </div>
      </template>
    </div>
    <div class="battles-players__info">
      <slot name="default" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import type {
  ICurrentPlayersEmits,
  ICurrentPlayersProps,
} from '~/features/battles/components/CurrentPlayers/CurrentPlayers.types';
import { EBattleStatuses } from '~/repository/modules/battles';

const device = useDevice();

const emits = defineEmits<ICurrentPlayersEmits>();
const props = withDefaults(defineProps<ICurrentPlayersProps>(), {
  slots: () => ({}),
});

const isThereWinner = computed(() => Object.values(props.slots).find((member) => !!member?.isWinner));

const defineLevelClasses = computed(() => (slot: number, rootClassName: string) => {
  const member = props.slots[slot];

  if (!member || member.bot || isThereWinner.value) return '';

  return assignLevelClasses(member.level, rootClassName);
});

const properStyles = computed(() => {
  const styles = {} as Record<number, CSSProperties>;

  for (const slot in props.slots) {
    const member = props.slots[slot];
    styles[slot] = {
      zIndex: Object.keys(props.slots).length - +slot + 1,
    };
    if (!member) continue;
    const parsedStyles = GlobalUtils.CSS.parseColor(GlobalUtils.Colors.parseLvlColors(member.level), {
      direction: GlobalUtils.Converting.toDegs(-35),
    });
    styles[slot] = {
      ...styles[slot],
      '--multi-lvl-color': parsedStyles.backgroundImage,
      '--single-lvl-color': parsedStyles.backgroundColor,
    };
  }

  return styles;
});

const assignLevelClasses = (level: number | null, className: string) => {
  const levelColors = GlobalUtils.Colors.parseLvlColors(level);
  return levelColors.length > 1 ? `${className}--multi-level` : `${className}--single-level`;
};
</script>

<style src="./CurrentPlayers.scss" lang="scss" scoped></style>
